export default [
	{
		"title": "About",
		"icon": "./icons/about.svg#about",
	},
	{
		"title": "Projects",
		"icon": "./icons/rocket.svg#rocket",
	},
	{
		"title": "Contact",
		"icon": "./icons/send.svg#send",
	}
];